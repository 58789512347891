import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { DataPerCardMKT } from "../DataPerCardMKT/DataPerCardMKT";

export const MarketingOverviewTabs = () => {
  return (
    <Tabs defaultValue="app_activity" className="grid grid-cols-2 gap-5">
      <TabsList className="col-start-2 justify-self-center w-fit h-14 justify-between rounded-2xl px-4 space-x-5">
        <TabsTrigger
          variant={"adminsTab"}
          className="w-1/2"
          value="app_activity"
        >
          Actividad APP
        </TabsTrigger>
        <TabsTrigger variant={"adminsTab"} className="w-1/2" value="rewards">
          Recompensas
        </TabsTrigger>
      </TabsList>
      <DataPerCardMKT className="col-span-2" />
      <DataPerCardMKT className="col-span-2" onlyCards />

    </Tabs>
  );
};
