import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import { TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import {
  BadgeCampaignCard,
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { FormFilterGraph, useGraphicsData } from "@/hooks";
import { Control } from "react-hook-form";

export const CarouselCampaignBadgesTabs = ({
  badges = [
    { isBadge: true, isCampaign: true },
    { isBadge: false, isCampaign: true },
    { isBadge: false, isCampaign: true },
  ],
}: {
  badges?: any[];
}) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_user",
      "brands",
      "sub_brands",
      "waste_categories",
      "wastes",
      "users_top",
    ],
    keysYear: ["year_history"],
  });
  return (
    <>
      <div className="flex w-full justify-center px-14 items-center">
        <Carousel opts={{ loop: true, align: "start" }} className="flex w-full">
          <CarouselContent className="-ml-2 md:-ml-4">
            <CampaignBadgesItems badges={badges} control={control} />
          </CarouselContent>
          <CarouselPrevious className="bg-white fill-white hidden lg:flex" />
          <CarouselNext className="bg-white fill-white hidden lg:flex" />
        </Carousel>
      </div>
    </>
  );
};

const CampaignBadgesItems = ({
  badges,
  control,
}: {
  badges: any[];
  control: Control<FormFilterGraph, any>;
}) => {
  return badges.map((uni_badge, index) => (
    <CarouselItem className={`basis-11/12 `} key={index}>
      <WasteValidationsContainer
        key={`uni_data_${index}`}
        title={`${index} Data`}
        defaultValueTabName="overview"
        onValueTabChange={(value: string) => {
          console.log(value);
        }}
        filter={
          <TabsList className="col-span-2 md:col-span-1 justify-self-center w-fit h-14 justify-between rounded-2xl px-4 ">
            <TabsTrigger
              variant={"adminsTab"}
              size={"small"}
              // className="w-1/2"
              value="overview"
            >
              Overview
            </TabsTrigger>
            <TabsTrigger
              variant={"adminsTab"}
              // className="w-1/2"
              value="rewards"
            >
              Recompensas
            </TabsTrigger>
            <TabsTrigger
              variant={"adminsTab"}
              // className="w-1/2"
              value="demographics"
            >
              Demográficos
            </TabsTrigger>
          </TabsList>
        }
      >
        <div className="w-full grid lg:grid-cols-3 grid-cols-2 gap-6">
          {badges.map((uni_data_map, idx) => (
            <WasteValidationsContainer
              key={`uni_card_data_${idx}`}
              className={`w-full`}
              title={"Kilos/toneladas  totales reciclados"}
              filter={
                <FilterPerYearController
                  name={"graphsRanges"}
                  control={control}
                />
              }
            >
              <CardNumOverview
                variant={"collection_centers"}
                className="w-9/12 aspect-square items-center"
              />
            </WasteValidationsContainer>
          ))}
        </div>
      </WasteValidationsContainer>
    </CarouselItem>
  ));
};
