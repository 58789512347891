import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import { WasteValidationsContainer } from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { CheckBoxGroup } from "../CheckBoxGroup/CheckBoxGroup";
import { Controller } from "react-hook-form";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { dataGraphicsTypeCollectionCenter } from "@/interfaces";
import { useTypeCollectionCenters } from "@/hooks/useTypeCollectionCenters";
import { fetcherOverviewDemographicsCollectionCentersRegistrations } from "@/pages/admins/collectioncenters/overview";
import { generateGraphic } from "@/shared/functions/generateGraphic";

type ProductActivityProps = {
  session: Session | null;
};

export const CollectionCenterDemographics = ({
  session,
}: ProductActivityProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_user",
      "brands",
      "sub_brands",
      "waste_categories",
      "wastes",
      "users_top",
    ],
    keysYear: ["year_history"],
  });

  const { type_collection_center, control_centers } = useTypeCollectionCenters({
    default_type_collection_center: "global",
  });

  const { data: stats, refetch } = useQuery({
    queryKey: ["overviewDemographicsCollectionCentersRegistrations"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDemographicsCollectionCentersRegistrations({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  useCheckRefetch(refetch, global, ranges, years);

  const dataGraphics_1: dataGraphicsTypeCollectionCenter = [
    {
      title: "Género",
      subtitle: "* Considerando usuarios que al menos han escaneado una vez",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "genders",
    },
    {
      title: "Edad",
      subtitle:
        "* Considerando usuarios que al menos han registrado una compra",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "ages",
    },
    {
      title: "Ubicaciones con más validaciones (TOP 10)",
      subtitle: "*Estado",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "states",
    },
    {
      title: "Ubicaciones con más validaciones (TOP 10)",
      subtitle: "*Municipio",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "towns",
    },
    {
      title: "Centros de acopio con más validaciones  por género (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "collection_centers_gender",
    },
    {
      title: "Centros de acopio con más validaciones  por edad (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "collection_centers_age",
    },
    {
      title: "Residuos con más validaciones  por género (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "wastes_gender",
    },
    {
      title: "Residuos con más validaciones  por edad (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "wastes_age",
    },
  ];
  return (
    <TabsContent value="Demographics">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <Controller
          name={"type_collection_center"}
          control={control_centers}
          render={({ field: { name, onChange, value } }) => (
            <CheckBoxGroup
              options={[
                {
                  text_label: "Centros de Acopio",
                  slug: "collection_centers",
                },
                {
                  text_label: "Puntos verdes",
                  slug: "green_points",
                },
                {
                  text_label: "Recolectores",
                  slug: "collectors",
                },
              ]}
              value={value}
              setValue={onChange}
            />
          )}
        />
        <FilterPerYearController name={"globalRange"} control={control} />
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        {dataGraphics_1.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`${item.span == 1 ? "" : "col-span-2"}`}
            variant={item.variant}
            title={item.title}
            subtitle={item.subtitle}
            filter={
              <FilterPerYearController
                name={item.nameFilter}
                control={control}
              />
            }
          >
            <OverviewItem
              graphicType={item.graphicType}
              data={
                stats &&
                generateGraphic(
                  stats,
                  item.name_graphic_data,
                  false,
                  item.name_graphic_data === "collection_centers_gender" ||
                    item.name_graphic_data === "collection_centers_age" ||
                    item.name_graphic_data === "wastes_gender" ||
                    item.name_graphic_data === "wastes_age"
                )
              }
            />
          </WasteValidationsContainer>
        ))}
      </div>
    </TabsContent>
  );
};
